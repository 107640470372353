import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { color, device, box } from '../../global/theme';
import { rgba } from 'polished';
import { useWindowWidth } from '../../global/custom-hooks/windowWidth';

const APPEARANCES = {
  PRIMARY: 'primary',
  PRIMARY_LIGHT: 'primaryLight',
  SECONDARY: 'secondary',
  TERTIARY: 'tertiary',
  LIGHTEST: 'lightest',
  MEDIUM_LIGHT: 'mediumLight',
  HERO: 'hero',
  IMAGE_SECTION: 'imageSection',
  POPOUT_LIGHT: 'popoutLight',
  POPOUT_PRIMARY: 'popoutPrimary',
  FOOTER: 'footer',
};

const HEIGHT = {
  SET: 'setHeight',
  AUTO: 'autoHeight',
};

export const StyledSection = styled.section`
  
  ${props =>
    props.noPadding &&
    `
      padding: 0 !important;
  `}

  ${props =>
    props.appearance === APPEARANCES.PRIMARY &&
    `
      background: ${color.primary};
      padding: ${box.halfGlobalPadding} 0;

      /* This is to counter the pop out image overlapping sections */
      @media ${device.tablet} {
        padding: ${box.globalPadding} 0;
      }
  `}

  ${props =>
    props.appearance === APPEARANCES.PRIMARY_LIGHT &&
    `
      background: ${color.primaryLight};
      padding: ${box.halfGlobalPadding} 0;

      /* This is to counter the pop out image overlapping sections */
      @media ${device.tablet} {
        padding: ${box.globalPadding} 0;
      }
  `}

  ${props =>
    props.appearance === APPEARANCES.SECONDARY &&
    `
      background: ${color.secondaryLight};
      padding: ${box.halfGlobalPadding} 0;

      /* This is to counter the pop out image overlapping sections */
      @media ${device.tablet} {
        padding: ${box.globalPadding} 0;
      }
    `}

  ${props =>
    props.appearance === APPEARANCES.TERTIARY &&
    `
      background: ${color.tertiary};
      padding: ${box.halfGlobalPadding} 0;

      /* This is to counter the pop out image overlapping sections */
      @media ${device.tablet} {
        padding: ${box.globalPadding} 0;
      }
    `}

  ${props =>
    props.appearance === APPEARANCES.LIGHTEST &&
    `
      background: ${color.lightest};
      padding: ${box.halfGlobalPadding} 0;

      /* This is to counter the pop out image overlapping sections */
      @media ${device.tablet} {
        padding: ${box.globalPadding} 0;
      }
    `}

    ${props =>
    props.appearance === APPEARANCES.MEDIUM_LIGHT &&
    `
      background: ${color.mediumlight};
      padding: ${box.halfGlobalPadding} 0;

      /* This is to counter the pop out image overlapping sections */
      @media ${device.tablet} {
        padding: ${box.globalPadding} 0;
      }
    `}

  ${props =>
  props.appearance === APPEARANCES.HERO &&
  `
    position: relative;
    background-image: ${`url(${props.imageUrl})`};
    height: ${props.heightMobile ? props.heightMobile : "auto"};
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;

    @media ${ device.mobileL } {
      height: calc(${props.height} + 5vw);
    }

    @media ${ device.laptop } {
      height: calc(${props.height} + 5vw);

      &::after {
        content: "";
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-image: linear-gradient(to bottom, ${rgba(color.darkest, 0.4)} 0%, ${rgba(color.darkest, 0.2)} 20%, transparent 40%);
      }
    }
  `}

  ${props =>
    props.appearance === APPEARANCES.IMAGE_SECTION &&
    `
      background-image: ${`url(${props.imageUrl})`};
      height: auto;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: top center;
      padding: calc(3rem + 5vw) 0;
  `}


  ${props =>
    props.appearance === APPEARANCES.POPOUT_LIGHT &&
    `
      background: ${color.secondaryLight};
      display: flex;
      align-items: center;
      padding: ${box.globalPadding} 0;
      flex-flow: column;

      @media ${device.tablet} {
        flex-flow: row;
        padding: ${box.globalPadding} 0;
      }
  `}

  ${props =>
    props.appearance === APPEARANCES.POPOUT_PRIMARY &&
    `
      background: ${color.primary};
      display: flex;
      align-items: center;
      padding: ${box.globalPadding} 0;
      flex-flow: column;

      @media ${device.tablet} {
        flex-flow: row;
        padding: ${box.globalPadding} 0;
      }
  `}

  ${props =>
    props.appearance === APPEARANCES.FOOTER &&
    `
      background: ${color.mediumlight};
      display: flex;
      align-items: flex-start;
      padding: ${box.globalPadding} 0;
      flex-flow: column;

      @media ${device.tablet} {
        flex-flow: row;
        padding: ${box.globalPadding} 0;
      }
  `}
`;

export const StyledSectionInner = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`;

const applyStyle = SectionWrapper => {
  return (
    SectionWrapper &&
    StyledSection.withComponent(({ isLoading, imageUrl, desktopImage, mobileImage,  ...rest }) => (
      <SectionWrapper  {...rest} />
    ))
  );
};

export function Section({
  imageUrl,
  isLoading,
  desktopImage,
  mobileImage,
  children,
  SectionWrapper,
  ...props
})

{
  const sectionInner = (
    <Fragment>
      {children}
    </Fragment>
  );

  const StyledSectionWrapper = React.useMemo(() => applyStyle(SectionWrapper), [SectionWrapper]);
  
  let SelectedSection = StyledSection;
  if (SectionWrapper) {
    SelectedSection = StyledSectionWrapper;
  }

  // Define the custom hook breakpoint
  imageUrl = useWindowWidth() >= 670 ? desktopImage : mobileImage;

  return (
    <SelectedSection imageUrl={ imageUrl } {...props}>
      {sectionInner}
    </SelectedSection>
  );
}

Section.propTypes = {
  children: PropTypes.node,
  appearance: PropTypes.oneOf(Object.values(APPEARANCES)),
  isDisabled: PropTypes.bool,
  /**
   Prevents users from clicking on a button multiple times (for things like payment forms)
  */
  isUnclickable: PropTypes.bool,
  /**
   Buttons with icons by themselves have a circular shape
  */
  containsIcon: PropTypes.bool,
  size: PropTypes.oneOf(Object.values(HEIGHT)),
  ButtonWrapper: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
};

Section.defaultProps = {
  size: HEIGHT.SET,
};