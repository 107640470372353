import React, { Fragment } from 'react'
import styled from 'styled-components'
import { ListItem } from '../atoms/typography/typography';

const ListGrid = styled.ul`
    grid-template-columns: repeat(3, minmax(100px, 1fr));
    grid-template-rows: repeat(auto-fit, minmax(auto, 1fr));
    display: grid;
    grid-gap: 0.5rem;
`;

const GridList = ({ card }) => {
    return (
        <ListGrid key={card.id}>
            {card.addGridList && ((
                card.listItem.map((single, index) => (
                    <Fragment key={index}>
                        <ListItem>{single.item}</ListItem>
                    </Fragment>
                ))
            ))}
        </ListGrid>
    );
}

export default GridList;
