
// This is a temporary generated file. Changes to this file will be overwritten eventually!
import React from "react"

import Layout from "../src/components/Layout"
import SEO from "../src/components/SEO"

// Sections
import CardList from '../src/layouts/CardList';

const Page = ({ pageContext }) => {
  const {
    page: { title, pageBuilder, themeGeneralSettings, theme },
  } = pageContext

  const layouts = pageBuilder.layouts || []
  // console.log(themeGeneralSettings)
  return (
    <Layout themeGeneralSettings={themeGeneralSettings} pageContext={pageContext}>
      <SEO title={title}/>

      {
        layouts.map((layout, index) => {
          
              if (layout.fieldGroupName === 'page_Pagebuilder_Layouts_CardList') {
                  return <CardList themeGeneralSettings={themeGeneralSettings} theme={theme} {...layout} key={index} />;
              } 
            
        })
      }

    </Layout>
  )
}

export default Page
  