import React, { Fragment } from 'react';
import { BadgeContainer, StyledIcon } from './style'

const Badge = ({ card }) => {
    // console.log(card)
    return (
        <Fragment>
            { card.addBadge && ((
                <BadgeContainer><StyledIcon icon={card.badgeIcon}/></BadgeContainer>
            ))}
        </Fragment>
    );
}

export default Badge;
