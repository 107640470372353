import React, { Fragment } from 'react';
import styled from 'styled-components'
// Components
import Wysiwyg from '../../components/Wysiwyg';
// Atoms
import { Card } from '../../atoms/card/Card';
import { Heading } from '../../atoms/typography/headings';
import { device } from '../../global/theme';
import { Column, SmallStack } from '../../global/grid';
import GridList from '../../components/GridList';
import Badge from '../../components/Badge/index';


const StyledColumn = styled(Column)`
    grid-column: ${props => props.isFullWidth ? "1 / span 1" : ""};
    grid-row: ${props => props.isFullWidth ? "span 1 !important" : "auto"};;

    @media ${device.tablet} {
        grid-column: ${props => props.isFullWidth ? "1 / span 4" : ""};

        &:nth-child(1) {
            grid-row: 1 / span 2;
        }
        
        &:nth-child(2) {
            grid-row: 2 / span 2;
        }
        
        &:nth-child(3) {
            grid-row: 3 / span 2;
        }
        
        &:nth-child(4) {
            grid-row: 4 / span 2;
        }
    }
`;

const CardItem = ({ card }) => {
    const text = card.text
    // console.log(card.isFullWidth)
    return(
        <Fragment>
            <StyledColumn key={card.id} flex column isFullWidth={card.isFullWidth}>
                <Card style={{ position:'relative' }} key={card.id} isTertiaryCard={true} >
                <SmallStack>
                    <Heading isH4={true}>{card.title}</Heading>
                        <Wysiwyg
                            text={text}
                        />
                        {card.addGridList && ((
                            <GridList
                                card={card}
                            />
                        ))}
                    </SmallStack>
                    <Badge card={card}/>
                </Card>
            </StyledColumn>
        </Fragment>
    )
}

export default CardItem