import React from 'react';
import styled, { css } from 'styled-components';
// import "typeface-montserrat";
import { flexUnit } from '../../global/mixin.js';
import { color, device, typography } from '../../global/theme';
import { easing } from '../../global/animations.js';

export const Label = styled.label`
  font-size: ${flexUnit(8, 13, 18, 'vw', 'font-size')};
  color: ${ color.medium };
  font-family: ${typography.type.primary};
  font-display: fallback;
  font-weight: 600;
  line-height: 1.3rem;
  text-align: left;

  /**
  * Sizing properties
  */

  ${props =>
    props.large && css`
      ${flexUnit(8, 13, 24, 'vw', 'font-size')};
      line-height: 1.45em;
      font-weight: 300;
  `}


  /**
  * Alignment properties
  */

  ${props => props.alignCenter && css`
    text-align: center;
  `}

  ${props => props.alignRight && css`
    text-align: right;
  `}

  /**
  * Colour properties
  */

  ${props => props.fontPrimary && css`
    color: ${ color.primary };
  `}

  ${props => props.fontMedium && css`
    color: ${ color.medium };
  `}

  ${props => props.fontWhite && css`
    color: ${ color.lightest };
  `}

  @media ${device.laptop} {
    line-height: 1.3em;
  }
`;

export const Input = styled.input`
  ${flexUnit(8, 13, 18, 'vw', 'font-size')};
  color: ${ color.darker };
  font-family: ${typography.type.primary};
  font-weight: 300;
  line-height: 1.3rem;
  text-align: left;
  border: none;
  border-bottom: 2px solid ${color.mediumlight};
  transition: ${easing.card};

  &:hover,
  &:focus,
  &:focus {
    border-bottom: 2px solid ${color.primary};
  }


  /**
  * Sizing properties
  */

  ${props =>
    props.large && css`
      ${flexUnit(8, 13, 24, 'vw', 'font-size')};
      line-height: 1.45em;
      font-weight: 300;
  `}


  /**
  * Alignment properties
  */

  ${props => props.alignCenter && css`
    text-align: center;
  `}

  ${props => props.alignRight && css`
    text-align: right;
  `}

  /**
  * Colour properties
  */

  ${props => props.fontPrimary && css`
    color: ${ color.primary };
  `}

  ${props => props.fontGray && css`
    color: ${ color.medium };
  `}

  ${props => props.fontWhite && css`
    color: ${ color.lightest };
  `}

  @media ${device.laptop} {
    line-height: 1.3em;
  }
`;

export const Quote = styled.blockquote`
  font-size: ${flexUnit(6, 10, 24, 'vw', 'font-size')};
  font-family: ${ typography.type.primary };
  font-weight: ${ typography.weight.semibold };
  letter-spacing: -1;
  line-height: 1.25em;

  /**
  * Sizing properties
  */

  ${props =>
    props.large && css`
      ${flexUnit(8, 13, 24, 'vw', 'font-size')};
      line-height: 1.45em;
      font-weight: 300;
  `}

  /**
  * Alignment properties
  */

  ${props => props.alignCenter && css`
    text-align: center;
  `}

  ${props => props.alignRight && css`
    text-align: right;
  `}

  /**
  * Colour properties
  */

  ${props => props.fontPrimary && css`
    color: ${ color.primary };
  `}

  ${props => props.fontGray && css`
    color: ${ color.medium };
  `}

  ${props => props.fontWhite && css`
    color: ${ color.lightest };
  `}
`;

export const Cite = styled.cite`
  font-size: ${flexUnit(8, 13, 18, 'vw', 'font-size')};
  color: ${ color.darker };
  font-family: 'Montserrat', sans-serif;
  font-weight: 300;
  line-height: 1.3rem;
  text-align: left;

  /**
  * Sizing properties
  */

  ${props =>
    props.large && css`
      ${flexUnit(8, 13, 24, 'vw', 'font-size')};
      line-height: 1.45em;
      font-weight: 300;
  `}

  /**
  * Alignment properties
  */

  ${props => props.alignCenter && css`
    text-align: center;
  `}

  ${props => props.alignRight && css`
    text-align: right;
  `}

  /**
  * Colour properties
  */

  ${props => props.fontPrimary && css`
    color: ${ color.primary };
  `}

  ${props => props.fontGray && css`
    color: ${ color.medium };
  `}

  ${props => props.fontWhite && css`
    color: ${ color.lightest };
  `}
`;

export const StyledErrorMessage = styled.p`
  color: ${color.primary};
  margin-top: 0.25rem;

  &:before {
    content: "❌ ";
    font-size: 10px;
  }
  @media (prefers-color-scheme: dark) {
    color: ${color.primaryLight};
  }
`;

export const Checkbox = styled.input`
  display: inline-block;
  height: 1rem;
  font-size: 1rem;
  border-radius: 5px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 2px solid ${color.mediumlight};
  width: 1rem;
  background-color: white;
  align-self: center;
  margin-right: 0.5rem;

  &:hover {
    cursor: pointer;
  }

  &:checked {
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="78.369" height="78.369" viewBox="0 0 78.369 78.369"><path fill="white" d="M78.05 19.015l-48.592 48.59c-.428.43-1.12.43-1.548 0L.32 40.016c-.427-.426-.427-1.12 0-1.547l6.704-6.704c.428-.427 1.12-.427 1.548 0l20.113 20.112 41.113-41.113c.43-.427 1.12-.427 1.548 0l6.703 6.704c.427.427.427 1.12 0 1.548z"/></svg>');
    background-size: contain;
    background-color: ${color.medium};
    border: 2px solid ${color.medium};
  }
`;

export const Radio = styled.input`
  appearance: none;
  border-radius: 50%;
  border: 2px solid ${color.mediumlight};
  height: 1rem;
  width: 1rem;
  margin-right: 0.5rem;
  align-self: center;
  justify-content: center;
  position: relative;
  display: flex;

  &:hover {
    cursor: pointer;
  }

  &:checked {
    border: 2px solid ${color.medium};
  }

  &:checked::before {
    content: "";
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    background-color: ${color.medium};
    align-self: center;
    border-radius: 50%;
  }
`;

export const TextArea = styled.textarea`
  overflow: hidden;
  font-family: ${typography.type.primary};
  ${flexUnit(8, 13, 18, 'vw', 'font-size')};
  color: ${ color.medium };
  box-sizing: border-box;
  width: 100%;
  height: 15ch;
  resize: vertical;
  padding-bottom: 0;
  border: 2px solid ${color.mediumlight};

  &:hover,
  &:focus,
  &:focus {
    border: 2px solid ${color.primary};
  }
`;

export const ListItem = styled.li`
    font-family: ${typography.type.primary};
    ${flexUnit(8, 13, 15, 'vw', 'font-size')};
    font-weight: ${ typography.weight.medium };
`;

export function Mailto({ email, subject, body, ...props }) {
  return (
    <a href={`mailto:${email}?subject=${encodeURIComponent(subject) || ''}&body=${encodeURIComponent(body) || ''}`}>{props.children}</a>
  );
}

/**
 * EXAMPLE
 *  <Mailto email="foo@bar.baz" subject="Hello & Welcome" body="Hello world!">
 *   Mail me!
 *  </Mailto>,

 */
