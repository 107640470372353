import React from "react"
// import styled from 'styled-components'
// Layout Components
import CardItem from './CardItem';
import LefthandTextColumn from "../TextBlock/LefthandTextColumn";
import RighthandTextColumn from "../TextBlock/RighthandTextColumn";
// Components
import Divider from "../../components/Divider/index";
// Atoms
import { Section } from '../../atoms/section/Section';
// Theme
import { Column, Container, Grid, Row, Wrapper } from '../../global/grid';
import { col } from "../../global/theme";


// Here we add the ACF feilds as props to use in the component
const CardList = ({ 
  title,
  subTitle,
  text,
  addButton,
  button,
  cards,
  addTextColumn,
  textColumn,
}) => {
  //console.log(cards)
  return (
      <Section>
        <Wrapper>
        <Row paddedRow>
          <Column selfStart xsWidth={col.twelve} mdWidth={col.six}>
            <LefthandTextColumn
              title={title}
              subTitle={subTitle}
              text={text}
              addButton={addButton}
              button={button}
            />
          </Column>
          <RighthandTextColumn
            addTextColumn={addTextColumn}
            textColumn={textColumn}
          />
        </Row>
          { cards && ((
            <Container>
                <Grid>
                  { cards.map((card, index) => (
                    <CardItem
                      card={card}
                      key={index}
                    />
                  ))}
                </Grid>
            </Container>
          ))}
        <Divider/>
        
      </Wrapper>
    </Section>
  )
};

export default CardList
