import React, { Fragment } from 'react';
// Components
import Wysiwyg from "../../components/Wysiwyg"
// Atoms
import { Button } from '../../atoms/button/Button';
// Theme
import { col, position } from '../../global/theme'
import { Column, InnerStack } from '../../global/grid'

const TextBlockTextColumn = ({ addTextColumn, textColumn, addButton, button }) => {
    return (
        <Fragment>
            { addTextColumn && ((
                <Column flex alignItems={position.end} xsWidth={col.twelve} mdWidth={col.six} xsPaddingTop={"0"} mdPaddingTop={"3rem"}>
                    <InnerStack>
                        <Wysiwyg
                            textColumn={textColumn}
                        />
                        {addButton && ((
                            <Button to={button.url} appearance="textLink">{button.title}</Button>
                        ))}
                    </InnerStack>
                </Column>
            ))}
        </Fragment>
    );
}

export default TextBlockTextColumn;
