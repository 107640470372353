import styled from 'styled-components'
// Components
import { Icon } from '../../atoms/icon/Icon';
import { device } from '../../global/theme';

const BadgeContainer = styled.div`
    position: absolute;
    padding: 0.5rem;
    top: 1rem;
    bottom: unset;
    right: 1rem;
    background: ${({ theme }) => theme.text};
    border-radius: 100%;

    @media ${device.mobileL} {
        top: unset;
        bottom: 1rem;
        padding: 1rem;
    }
`;

const StyledIcon = styled(Icon)`
    fill: ${({ theme }) => theme.primaryDarkestTertiary};
`;

export { BadgeContainer, StyledIcon };